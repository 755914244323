import React from "react"
import Layout from "../component/layout"

function ComplianceAndSafety () {


    return(
        <Layout>

        </Layout>
    )
}

export default ComplianceAndSafety