
export const backend_url = 'https://api.payserve.co.ke'
export const checkEmailAndPasswordURL = '/api/auth/check_email_and_password'
export const loginURL = '/api/auth/login'
export const forgotPasswordURL = '/api/auth/forgot_password'
export const resetPasswordURL = '/api/auth/reset_password'
export const codeVerificationURL = '/api/auth/otp'
export const resendCodeURL = '/api/auth/resend_code'
export const verifyOTPURL = '/api/auth/verify_otp'
export const checkTokenExpirationURL = '/api/auth/check_jwt_expiration'
export const addWaterConcentratorURL = '/api/core/water/add_concentrator'
export const getWaterConcentratorsURL = '/api/core/water/get_concentrators'
export const importWaterConcentratorsURL = '/api/core/water/import_concentrators'
export const addWaterMeterURL = '/api/core/water/add_water_meter'
export const getWaterMetersURL = '/api/core/water/get_water_meters'
export const importWaterMetersURL = '/api/core/water/import_water_meters'
export const confirmCompanyNameURL = '/api/core/company_management/confirm_company_name'
export const getCompaniesURL = '/api/core/company_management/get_companies'
export const addCompanyURL = '/api/core/company_management/add_company'
export const addExistingCompanyToUserURL = '/api/core/user_management/add_a_existing_company_to_user'
export const addNewCompanyToUserURL = '/api/core/user_management/add_a_new_company_to_user'
export const getCompanyUsersURL = '/api/core/user_management/get_company_users'
export const confirmUserEmailURL = '/api/core/user_management/confirm_user_email'
export const getSitesURL = '/api/core/site_management/get_sites'
export const addSiteURL = '/api/core/site_management/add_site'
export const getProjectsURL = '/api/core/project_management/get_projects'
export const getTransactionsURL = ''
export const getWaterMeterAccountURL = ''
export const getAPIMetricsURL = '/api/core/api_management/get_metrics'
export const getListofCompanies = '/api/app/user_management/get_list_of_companies'
export const getListOfFacilities = '/api/app/user_management/get_list_of_facilities'
export const getUnitsForFacility = '/api/app/facility_management/get_units_for_facility'
export const getFacilities = '/api/app/facility_management/get_facilities'
export const getDivisionArray = '/api/app/facility_management/get_divisions'
export const getUnitUrl = '/api/app/unit_management/get_unit'
export const getUnitAssetsURL = '/api/app/unit_management/get_unit_assets'
export const addUnitAssetURL = '/api/app/unit_management/add_new_unit_asset'
export const deleteUnitAssetURL = '/api/app/unit_management/delete_unit_asset'
export const updateUnit = '/api/app/unit_management/update_unit'
export const getFacilityUnits = '/api/app/unit_management/get_facility_units'
export const AddNewCustomerURL = '/api/app/customer_management/add_new_customer'
export const getCustomersURL = '/api/app/customer_management/get_customers'
export const getCustomerURL = '/api/app/customer_management/get_customer'
export const EnableCustomer = '/api/app/customer_management/enable_customer'
export const DisableCustomer = '/api/app/customer_management/disable_customer'
export const updateCustomer = '/api/app/customer_management/update_customer'
export const getCustomerUnits = '/api/app/customer_management/get_customer_units'
export const addUnoccupiedUnitToCustomer = '/api/app/customer_management/add_unoccupied_unit_to_customer'
export const addFamilyMemberToCustomer = '/api/app/customer_management/add_family_member_to_customer'
export const getFamilyMembersForCustomer = '/api/app/customer_management/get_family_members_for_customer'
export const addVehicleToCustomer = '/api/app/customer_management/add_vehicle_to_customer'
export const getVehiclesForCustomer = '/api/app/customer_management/get_vehicles_for_customer'
export const addStaffToCustomer = '/api/app/customer_management/add_staff_to_customer'
export const getStaffForCustomer = '/api/app/customer_management/get_staff_for_customer'
export const deleteFamilyMemberURL = '/api/app/customer_management/delete_family'
export const handleFamilyMemberStatusURL = '/api/app/customer_management/handle_family_status'
export const deleteVehicleURL = '/api/app/customer_management/delete_vehicle'
export const handleVehicleStatusURL = '/api/app/customer_management/handle_vehicle_status'
export const deleteStaffURL = '/api/app/customer_management/delete_staff'
export const handleStaffStatusURL = '/api/app/customer_management/handle_staff_status'
export const visitorPreRegistrationURL = '/api/app/visitor_management/visitor_pre_registration'
export const visitorGuardRegistrationURL = '/api/app/visitor_management/visitor_guard_registration'
export const getVisitLogsForFacilityURL = '/api/app/visitor_management/get_visitor_logs'
export const getVisitLogURL = '/api/app/visitor_management/get_visitor_log'
export const searchByOtp = '/api/app/visitor_management/search_by_otp'
export const exitVisitLogURL = '/api/app/visitor_management/exit_visit_log'
export const deleteVisitorURL = '/api/app/visitor_management/delete_visitor'
export const confirmQRDataURL = '/api/app/visitor_management/confirm_qr_data'
export const confirmManualDataURL = '/api/app/visitor_management/manual_entry'
export const handleAllowVisitorURL = '/api/app/visitor_management/allow_verified_visitor'
export const requestVisitConfirmationURL = '/api/app/visitor_management/request_visit_confirmation'
export const GuardRegistrationURL = '/api/app/guard_management/add_guard'
export const allowVisitURL = '/api/app/visitor_management/allow_visit'
export const allowVisitorURL = '/api/app/visitor_management/allow_visitor'
export const getFacilityGuards = '/api/app/guard_management/get_facility_guards'
export const getGuardTime = '/api/app/guard_management/get_guard_time'
export const editFacilityGuard = '/api/app/guard_management/edit_facility_guard'
export const deleteFacilityGuard = '/api/app/guard_management/delete_facility_guard'
export const disableFacilityGuard = '/api/app/guard_management/disable_facility_guard'
export const addContractURL = '/api/app/levy_management/add_contract'
export const EntryAndExitURL = '/api/app/entry_and_exit_management/add_entry_and_exit'
export const getEntriesAndExitsForFacility = '/api/app/entry_and_exit_management/get_entries_and_exits_for_facility'
export const editEntriesAndExitsForFacility = '/api/app/entry_and_exit_management/edit_entries_and_exits_for_facility'
export const deleteEntriesAndExitsForFacility = '/api/app/entry_and_exit_management/delete_entries_and_exits_for_facility'
export const disableEntriesAndExitsForFacility = '/api/app/entry_and_exit_management/disable_entries_and_exits_for_facility'
export const getVisitorsForFacility = '/api/app/visitor_management/get_facility_visitors'
export const addLevy = '/api/app/levy_management/add_levy'
export const editLevy = '/api/app/levy_management/edit_levy'
export const deleteLevy = '/api/app/levy_management/delete_levy'
export const disableLevy = '/api/app/levy_management/disable_levy'
export const getLevies = '/api/app/levy_management/get_levies'
export const addLevyType = '/api/app/levy_management/add_levy_type'
export const getLevyTypes = '/api/app/levy_management/get_levy_types'
export const deleteLevyType = '/api/app/levy_management/delete_levy_type'
export const addContract = '/api/app/levy_management/add_contract'
export const getContracts = '/api/app/levy_management/get_contracts'
export const getContractURL = '/api/app/levy_management/get_contract'
export const getContractUnits = '/api/app/levy_management/get_contract_units'
export const deleteContract = '/api/app/levy_management/delete_contract'
export const disableContract = '/api/app/levy_management/disable_contract'
export const addUnitToContractURL = '/api/app/levy_management/add_unit_to_contract'
export const addPenalty = '/api/app/settings_management/add_penalty'
export const getPenalties = '/api/app/settings_management/get_penalties'
export const editPenalty = '/api/app/settings_management/edit_penalty'
export const deletePenalty = '/api/app/settings_management/delete_penalty'
export const disablePenalty = '/api/app/settings_management/disable_penalty'
export const addTax = '/api/app/settings_management/add_tax'
export const getTaxes = '/api/app/settings_management/get_taxes'
export const deleteTax = '/api/app/settings_management/delete_tax'
export const disableTax = '/api/app/settings_management/disable_tax'
export const addReminder = '/api/app/settings_management/add_reminder'
export const getReminders = '/api/app/settings_management/get_reminders'
export const editReminder = '/api/app/settings_management/edit_reminder'
export const deleteReminder = '/api/app/settings_management/delete_reminder'
export const disableReminder = '/api/app/settings_management/disable_reminder'
export const addInvoiceBillingURL = '/api/app/settings_management/add_invoice_billing'
export const getInvoiceBillingURL = '/api/app/settings_management/get_invoice_billing'
export const addBankDetails = '/api/app/settings_management/add_bank_details'
export const getBankDetails = '/api/app/settings_management/get_bank_details'
export const addDefaultAccount = '/api/app/settings_management/add_default_account'
export const removeDefaultAccount = '/api/app/settings_management/remove_default_account'

