import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { visitorPreRegistrationURL, getUnitsForFacility, getCustomersURL, getCustomerUnits, getDivisionArray } from '../../../../utils/urls';
import { getLocation } from '../../../../utils/getLocation';


function GateRegistration() {

    const selectedFacilityId = getItem('selectedFacilityId');
    const facilityId = selectedFacilityId ? selectedFacilityId : null;

    const [units, setUnits] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [entries, setEntries] = useState([])
    const [unitsPerDivision, setUnitsPerDivision] = useState([])

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [carRegistration, setCarRegistration] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [houseId, setHouseId] = useState('');
    const [resident, setResident] = useState('');
    const [carMake, setCarMake] = useState('');
    const [carColor, setCarColor] = useState('');
    const [carOccupants, setCarOccupants] = useState('');
    const [visitorType, setVisitorType] = useState('');
    const [entry, setEntry] = useState('');
    const [divisionArray, setDivisionArray] = useState([]);
    const [division, setDivision] = useState('');
    const [confirmVisit, setConfirmVisit] = useState(true)


    const fetchUnitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setUnits(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };


    const fetchDivisionArray = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            console.log(value)
            const response = await makeRequest2(`${getDivisionArray}/${value}`, 'GET', {});
            if (response.success) {
                setDivisionArray(response.data);
            } else {
                throw new Error('Failed to fetch divisions')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };


    const fetchCustomersForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getCustomersURL}/${value}`, 'GET', {});

            if (response.success) {
                setCustomers(response.data);
            } else {
                throw new Error('Failed to fetch customers')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };


    const handleVisitorSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const { latitude: lat, longitude: long } = await getLocation();

            const visitorData = {
                firstName,
                lastName,
                phoneNumber,
                division,
                lat: lat,
                long: long,
                houseId: selectedUnits._id,
                entryPointId: entry._id,
                confirmVisit,
            };


            if (!visitorData.firstName) throw new Error('First name is required.');
            if (!visitorData.lastName) throw new Error('Last name is required.');
            if (!visitorData.phoneNumber) throw new Error('Phone number is required.');


            const response = await makeRequest2(visitorPreRegistrationURL + '/' + facilityId, 'POST', visitorData);

            if (response.success) {
                toastify('Visitor added successfully', 'success');
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setIdNumber('');
                setHouseNumber('');
                setResident('');
                setHouseId('')
                setConfirmVisit(!confirmVisit)
            }
            else {
                throw new Error(response.error);
            }

        } catch (err) {
            toastify(err.message, 'error')
        }
    }
    const getCurrentAccessPoint = async () => {
        try {

            const value = await getItem('selectedEntryPoint');
            if (value) {
                const data = JSON.parse(value)
                setEntry(data)
            }


        }
        catch (err) {
            console.log(err.message)
        }
    }

    const handleClear = () => {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setIdNumber('');
        setCarRegistration('');
        setHouseNumber('');
        setCarMake('');
        setCarColor('');
        setCarOccupants('');
        setVisitorType('');
        setResident('');
        setConfirmVisit('');

    }

    useEffect(() => {
        fetchDivisionArray();
        fetchUnitsForFacility();
        fetchCustomersForFacility();
        getCurrentAccessPoint()
    }, []);


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Gate Registration</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-header">
                    <h5>Gate Registration</h5>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card-body">
                            <div className="container">

                                <div className="card">
                                    <div className="card-header">
                                        <h5>Visitor Information</h5>
                                    </div>

                                    <div className="card-body">
                                        <div className='container'>
                                            <div className='row'>
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>First Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="First name"
                                                            value={firstName} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setFirstName(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="Last name"
                                                            value={lastName} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setLastName(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Phone Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="Phone number"
                                                            value={phoneNumber} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setPhoneNumber(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Road</label>
                                                        <select
                                                            className="form-control mt-2"
                                                            value={division}
                                                            onChange={(e) => {
                                                                setDivision(e.target.value)
                                                                if (e.target.value === '') {
                                                                    setUnitsPerDivision([])
                                                                }
                                                                else {
                                                                    let filter = units.filter((x) => {
                                                                        return x.division === e.target.value
                                                                    })
                                                                    setUnitsPerDivision(filter)
                                                                }
                                                            }}
                                                        >
                                                            <option value="">-- Select --</option>
                                                            {divisionArray.map((division) => (
                                                                <option key={division._id} value={division._id}>
                                                                    {division.divisionName}
                                                                </option>
                                                            ))}
                                                            <option value="Block 2">Block 2</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Unit</label>
                                                        <Autocomplete
                                                            options={unitsPerDivision}
                                                            getOptionLabel={(option) => option.name || ''}
                                                            value={selectedUnits}
                                                            onChange={(event, newValue) => setSelectedUnits(newValue)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Units"
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <Chip
                                                                        key={option._id}
                                                                        label={option.name || ''}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                        />

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <input
                                                type="checkbox"
                                                checked={confirmVisit}
                                                value={confirmVisit}
                                                onChange={(e) => setConfirmVisit(!confirmVisit)}
                                                style={{ padding: 20 }}
                                            /> &nbsp;
                                            <label className="form-label">Send a Confirmation of Visit to Resident</label>

                                        </div>
                                    </div>

                                </div>

                                <div className="text-end mt-2">
                                    <button type="button" className="btn btn-outline-secondary" onClick={handleClear}>Clear</button>
                                    &nbsp;
                                    <button type="button" className="btn btn-primary" onClick={handleVisitorSubmit}>Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </Layout >
    )
}


export default GateRegistration