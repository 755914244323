import {
    createBrowserRouter
} from "react-router-dom";

// PAGES
import Login from '../components/authentication/login'
import ForgotPassword from '../components/authentication/forgotPassword'
import CheckEmail from '../components/authentication/checkEmail';
import CodeVerification from '../components/authentication/codeVerification';
import ResetPassword from '../components/authentication/resetPassword';
import Error404Page from '../components/error/Error404Page'

// APP
import Dashboard from '../components/app/dashboard/dashboard';
import Facilities from '../components/app/facility_management/facilities'
import AccessPoints from '../components/app/access_points/access_points'
import AddNewFacility from '../components/app/facility_management/add_new_facility'

import CampaignManagement from '../components/app/compaign_management/campaign_management';
import TicketManagement from '../components/app/ticket_management/ticket_management';
import SurveyManagement from '../components/app/survey_management/survey_management'
import Setting from '../components/app/settings/setting'

// FACILITY

import DashboardFacility from '../components/facility/dashboard/dashboard'
import UnitManagement from '../components/facility/unit_management/unit_management'
import ViewUnit from '../components/facility/unit_management/view_unit'
import ImportUnits from '../components/facility/unit_management/import_units'
import AddNewUnit from '../components/facility/unit_management/add_new_unit'
import MeterManagement from '../components/facility/utility_management/meter_management/meters'
import BillingManagement from '../components/facility/utility_management/billing_management/billing'
import UtilityReports from '../components/facility/utility_management/reports/report'
import ViewWaterMeter from '../components/facility/utility_management/meter_management/view_water_meter'
import ViewPowerMeter from "../components/facility/utility_management/meter_management/view_power_meter";
import ViewWaterCustomerAccount from '../components/facility/utility_management/billing_management/view_water_customer_account';
import ViewPowerCustomerAccount from "../components/facility/utility_management/billing_management/view_power_customer_account";
import LeaseManagement from "../components/facility/lease_management/lease_management/lease_management";
import RentInvoicingManagement from '../components/facility/lease_management/rent_invoicing/rent_invoicing'
import LeaseReports from '../components/facility/lease_management/reports/reports'
import LevyManagement from "../components/facility/levy_management/levy_management/levy_management";
import LevyInvoicingManagement from '../components/facility/levy_management/Invoicing/levy_invoicing'
import LevyReports from '../components/facility/levy_management/reports/reports'
import ExpenseManagement from "../components/facility/expense_management/expense_management/expense_management";
import ExpenseReports from '../components/facility/expense_management/reports/reports'
import VisitorAccessManagementHome from '../components/facility/visitor_access_management/home/home'
import VisitorRegistration from "../components/facility/visitor_access_management/visitor_registration/gate_registration";
import WaitingList from "../components/facility/visitor_access_management/wait_list/waiting_list";
import VisitorList from "../components/facility/visitor_access_management/visitor_registration/visitor_list";
import VisitorRequests from "../components/facility/visitor_access_management/visitor_registration/visitor_requests";
import GuardRegistration from "../components/facility/visitor_access_management/guard_registration/guard_registration";
import RegisterGuard from "../components/facility/visitor_access_management/guard_registration/register_guard";
import GuardManagement from "../components/facility/visitor_access_management/guard_registration/guard_management";
import EntriesAndExits from "../components/facility/visitor_access_management/entries_and_exits/entries_and_exits";
import AddEntryAndExit from "../components/facility/visitor_access_management/entries_and_exits/add_entry_and_exit";
import ViewDigitalLog from '../components/facility/visitor_access_management/digital_logs/get_digital_log'
import DigitalLogs from "../components/facility/visitor_access_management/digital_logs/digital_logs";
import AccessControl from "../components/facility/visitor_access_management/access_control/access_control";
import ScanQRCode from '../components/facility/visitor_access_management/qrcode/scan_qr_code'
import CustomerManagement from "../components/facility/customer_management/customer_management";
import AddNewCustomer from "../components/facility/customer_management/add_new_customer";
import ViewCustomer from "../components/facility/customer_management/view_customer";
import AddLevy from "../components/facility/levy_management/levy_management/add_levy";
import LevyContract from "../components/facility/levy_management/levy_contract/levy_contract";
import AddContract from "../components/facility/levy_management/levy_contract/add_contract";
import ViewContract from "../components/facility/levy_management/levy_contract/view_contract";
import WorkOrderManagement from "../components/facility/maintenance_management/work_order_management";
import PreventiveMaintenance from "../components/facility/maintenance_management/preventive_maintenance";
import ServiceVendor from "../components/facility/maintenance_management/service_vendor";
import StockAndSpareParts from "../components/facility/maintenance_management/stock_and_spare_parts";
import ComplianceAndSafety from "../components/facility/maintenance_management/compliance_and_safety";
import AssetManagement from "../components/facility/maintenance_management/asset_management";
import Settings from '../components/facility/settings/settings'
import VerifyCode from "../components/facility/visitor_access_management/verifycode/verify_code";
import ViewWaitingList from "../components/facility/visitor_access_management/wait_list/view_waiting_list";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },

    {
        path: "/forgot_password",
        element: <ForgotPassword />,
    },
    {
        path: "/check_email",
        element: <CheckEmail />,
    },
    {
        path: "/code_verification",
        element: <CodeVerification />,
    },
    {
        path: "/reset_password/:userId",
        element: <ResetPassword />,
    },
    {
        path: "/app/",
        element: <Dashboard />,
    },
    {
        path: "/app/facilities",
        element: <Facilities />,
    },
    {
        path: "/app/access_points",
        element: <AccessPoints />,
    },
    {
        path: "/app/add_new_facility",
        element: <AddNewFacility />,
    },
    {
        path: "/app/facility-details",
        element: <Dashboard />,
    },
    {
        path: "/app/campaign_management",
        element: <CampaignManagement />
    },
    {
        path: "/app/ticket_management",
        element: <TicketManagement />
    },
    {
        path: "/app/survey_management",
        element: <SurveyManagement />
    },
    {
        path: "/app/settings",
        element: <Setting />,
    },
    {
        path: "/facility/",
        element: <DashboardFacility />
    },

    {
        path: "/facility/unit_management",
        element: <UnitManagement />
    },
    {
        path: "/facility/settings",
        element: <Settings />,
    },
    {
        path: "/facility/unit_management/view_unit/:unitId",
        element: <ViewUnit />
    },
    {
        path: "/facility/unit_management/add_new_unit",
        element: <AddNewUnit />
    },
    {
        path: "/facility/unit_management/import_units",
        element: <ImportUnits />
    },
    {
        path: "/facility/customer_management",
        element: <CustomerManagement />
    },
    {
        path: "/facility/customer_management/add_new_customer",
        element: <AddNewCustomer />
    },
    {
        path: "/facility/customer_management/view_customer/:customerId",
        element: <ViewCustomer />
    },
    {
        path: "/facility/utility_management/meter_management/",
        element: <MeterManagement />
    },
    {
        path: "/facility/utility_management/meter_management/view_water_meter",
        element: <ViewWaterMeter />
    },
    {
        path: "/facility/utility_management/meter_management/view_power_meter",
        element: <ViewPowerMeter />
    },
    {
        path: "/facility/utility_management/meter_management/view_water_customer_account",
        element: <ViewWaterCustomerAccount />
    },
    {
        path: "/facility/utility_management/meter_management/view_power_customer_account",
        element: <ViewPowerCustomerAccount />
    },
    {
        path: "/facility/utility_management/billing",
        element: <BillingManagement />
    },
    {
        path: '/facility/utility_management/reports',
        element: <UtilityReports />
    },
    {
        path: "/facility/lease_management/",
        element: <LeaseManagement />
    },
    {
        path: "/facilitiy/lease_management/rent_invoicing",
        element: <RentInvoicingManagement />
    },
    {
        path: "/facilitiy/lease_management/reports",
        element: <LeaseReports />
    },
    {
        path: "/facility/levy_management/levy_management/levy_management",
        element: <LevyManagement />
    },
    {
        path: "/facilitiy/levy_management/levy_invoicing",
        element: <LevyInvoicingManagement />
    },
    {
        path: "/facilitiy/levy_management/reports",
        element: <LevyReports />
    },
    {
        path: "/facility/levy_management/levy_contract/levy_contract",
        element: <LevyContract />
    },
    {
        path: "/facility/levy_management/levy_contract/add_contract",
        element: <AddContract />
    },
    {
        path: "/facility/levy_management/levy_contract/view_contract/:contractId",
        element: <ViewContract />
    },
    {
        path: "/facility/levy_management/levy_management/add_levy",
        element: <AddLevy />
    },
    {
        path: "/facility/expense_management",
        element: <ExpenseManagement />
    },
    {
        path: "facilitiy/expense_management/reports",
        element: <ExpenseReports />
    },
    {
        path: "/facility/visitor_access_management/home",
        element: <VisitorAccessManagementHome />,
    },
    {
        path: "facility/visitor_access_management/visitor_registration/gate_registration",
        element: <VisitorRegistration />
    },
    {
        path: "facility/visitor_access_management/qrcode/scan_qr_code",
        element: <ScanQRCode />
    }, {
        path: "facility/visitor_access_management/code/verify",
        element: <VerifyCode />
    },
    {
        path: "/facility/visitor_access_management/visitor_registration/visitor_list",
        element: <VisitorList />
    },
    {
        path: "/facility/visitor_access_management/visitor_registration/visitor_requests",
        element: <VisitorRequests />
    },
    {
        path: "/facility/visitor_access_management/guard_registration/guard_registration",
        element: <GuardRegistration />
    },
    {
        path: "/facility/visitor_access_management/guard_registration/register_guard",
        element: <RegisterGuard />
    },
    {
        path: "/facility/visitor_access_management/waiting_list",
        element: <WaitingList />
    },
    {
        path: "/facility/visitor_access_management/view_waiting_list/:visitLogId",
        element: <ViewWaitingList />
    },
    {
        path: "/facility/visitor_access_management/guard_registration",
        element: <GuardManagement />
    },
    {
        path: "/facility/visitor_access_management/digital_logs/",
        element: <DigitalLogs />
    },
    {
        path: "/facility/visitor_access_management/view_digital_log/:visitLogId",
        element: <ViewDigitalLog />
    },
    {
        path: "/facility/visitor_access_management/entries_and_exits",
        element: <EntriesAndExits />
    },
    {
        path: "/facility/visitor_access_management/entries_and_exits/add_entry_and_exit",
        element: <AddEntryAndExit />
    },

    {
        path: "/facilitiy/visitor_management/access_control",
        element: <AccessControl />
    },
    {
        path: "/facility/maintenance_management/work_order_management",
        element: <WorkOrderManagement />
    },
    {
        path: "/facility/maintenance_management/preventive_maintenance",
        element: <PreventiveMaintenance />
    },
    {
        path: "/facility/maintenance_management/service_vendor",
        element: <ServiceVendor />
    },
    {
        path: "/facility/maintenance_management/stock_and_spare_parts",
        element: <StockAndSpareParts />
    },
    {
        path: "/facility/maintenance_management/compliance_and_safety",
        element: <ComplianceAndSafety />
    },
    {
        path: "/facility/maintenance_management/asset_management",
        element: <AssetManagement />
    },
    {
        path: "*",
        element: <Error404Page />,
    },
]);
