import React from "react"
import Layout from '../component/layout'


function WorkOrderManagement() {



    return(
        <Layout>
            
        </Layout>
    )
}

export default WorkOrderManagement