
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { getVisitLogsForFacilityURL, searchByOtp } from '../../../../utils/urls';
import { filterObjectsByAllProperties } from '../../../../utils/filterSearch';
import formatISODate from '../../../../utils/formatDate';

function DigitalLogs() {

    const [visitLogs, setVisitLogs] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [userRole, setRole] = useState("")


    const getCurrentUser = async () => {
        try {
            const currentUserValue = await getItem('APPUSER')
            if (currentUserValue) {
                const currentUser = JSON.parse(currentUserValue)
                const role = currentUser.user.role
                setRole(role)
                fetchVisitLogs(role);
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }

    const fetchVisitLogs = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(getVisitLogsForFacilityURL + '/' + facilityId, 'GET', {});

            if (response.success) {
                let logs = response.data.data;                

                // Filter logs to show only "checked in" status for guards
                if (userRole === 'guard') {
                    logs = logs.filter(log => log.status === 'Checked In');
                    console.log("Filtered Logs for Guard:", logs);
                    console.log("User Role:", userRole);
                }
                setVisitLogs(logs);
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const startTimeTemplate = (rowData)=>{

        if(rowData.startTime !== null){
          return formatISODate(rowData.startTime)
        }
        else{
          return `...`
        }
      }
      const endTimeTemplate = (rowData)=>{
        if(rowData.endTime !== null){
          return formatISODate(rowData.endTime)
        }
        else{
          return `...`
        }
        
      }
    // const handleInputSearch = async (value) => {
    //     try {
    //         setSearchTerm(value);
    //         if (value === '') {
    //             fetchVisitLogs()
    //         } else {
    //             const results = await filterObjectsByAllProperties(visitLogs, value);
    //             setVisitLogs(results);
    //         }
    //     } catch (error) {
    //         toastify(error.message, 'error');
    //     }
    // };

    const handleOtpSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchVisitLogs();
            }
            const response = await makeRequest2(searchByOtp + '/' + value, 'GET', {});
            console.log("RESPONSE", response)

            if (response.success) {
                setVisitLogs([response.data.data]);
            } else {
                setVisitLogs([]);
            }
            
        } catch (error) {
            toastify(error.message, 'error');
        }
    };
    
    
    const statusTemplate = (rowData) => {
        const status = rowData.status
        const statusStyles = {
            'Visit Confirmation': {
                color: '#ff9800',         // Orange
                backgroundColor: '#fff3e0' // Light Orange
            },
            'Scheduled': {
                color: '#4caf50',         // Green
                backgroundColor: '#e8f5e9' // Light Green
            },
            'Checked In': {
                color: '#2196f3',         // Blue
                backgroundColor: '#e3f2fd' // Light Blue
            },
            'Checked Out': {
                color: '#9c27b0',         // Purple
                backgroundColor: '#f3e5f5' // Light Purple
            },
            'Cancelled': {
                color: '#f44336',         // Red
                backgroundColor: '#ffebee' // Light Red
            }
        };



        return (
            <span
                style={{
                    fontWeight: 'bold',
                    padding: '5px 10px',
                    borderRadius: '15px',
                    ...statusStyles[status] // Apply styles based on status
                }}
            >
                {status}
            </span>
        );



    };
    const HandeFilter = async (status) => {
        try {
            if (status === 'All') {
                fetchVisitLogs()
            }
            else {

                const results = await visitLogs.filter((x) => { return x.status === status })
                setVisitLogs(results);
            }
        }
        catch (error) {
            toastify(error.message, 'error')
        }
    }
    const nameTemplate = (rowData) => {
        return <Link to={'/facility/visitor_access_management/view_digital_log/' + rowData._id}>
            {rowData.visitorName}
        </Link>
    }
    useEffect(() => {
        fetchVisitLogs()
        getCurrentUser()
    }, [])


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/visitor_access_management/digital_logs"}>Digital Logs</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchTerm}
                                        onChange={(e) => { handleInputSearch(e.target.value) }}
                                    />
                                </div> */}
                                <div className="col-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchTerm}
                                        onChange={(e) => { handleOtpSearch(e.target.value) }}
                                    />
                                </div>
                                <div className="col-3" >
                                    <div className="btn-group-dropdown" >
                                        <button
                                            className="btn btn-outline-default dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="ti ti-filter"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('All') }}>
                                                All
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Visit Confirmation') }}>
                                                Visit Confirmation
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Scheduled') }}>
                                                Scheduled
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Checked In') }}>
                                                Checked In
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Checked Out') }}>
                                                Checked Out
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Cancelled') }}>
                                                Cancelled
                                            </Link>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 mt-4">
                                    <DataTable
                                        value={visitLogs}
                                        
                                        emptyMessage="No Digital Logs Found."
                                        sortMode="multiple"
                                        paginator
                                        rows={10}
                                        stripedRows
                                        tableStyle={{ minWidth: '50rem' }}
                                    >

                                        <Column header="Visitor Name" body={nameTemplate} />
                                        <Column field="houseNumber" header="House No" />
                                        <Column field="status" header="Status" body={statusTemplate} sortable />
                                        <Column body={startTimeTemplate} header="Checkin Time" />
                                        <Column body={endTimeTemplate} header="Checkout Time" />
                                    </DataTable>

                                </div>


                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </Layout>
    )
}


export default DigitalLogs