import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { getListOfFacilities, getEntriesAndExitsForFacility } from '../../../utils/urls'
import { setItem, getItem } from '../../../utils/localStorage'


function AccessPoints() {
    const navigate = useNavigate()
    const [accessPoints, setAccessPoints] = useState([])

    const getAccessPoints = async () => {
        try {
            const currentUserValue = await getItem('APPUSER')
            if (currentUserValue) {
                const currentUser = JSON.parse(currentUserValue)
                const facilityId = currentUser.user.facilityId
                const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${facilityId}`, 'GET', {});

                if (response.success) {
                    setAccessPoints(response.data);
                } else {
                    throw new Error('Failed to fetch entries and exits')
                }

            }
        }
        catch (err) {
            console.log(err.message)
        }
    }
    const setUserFacilityId = async () => {
        try {
            const currentUserValue = await getItem('APPUSER')
            if (currentUserValue) {
                const currentUser = JSON.parse(currentUserValue)
                const facilityId = currentUser.user.facilityId
                await setItem('selectedFacilityId', facilityId)


            }
        }
        catch (err) {
            console.log(err.message)
        }
    }
    useEffect(() => {
        setUserFacilityId()
        getAccessPoints()

    }, [])
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="javascript: void(0)">Access Points</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                {/* <div className="card-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ float: 'right' }}>
                                <button className="btn btn-primary" onClick={() => navigate('/app/add_new_facility')}>
                                    <i className="ti ti-plus pt-3"></i> Add New Facility
                                </button>
                            </div>
                        </div>
                    </div>
                </div>  */}
                <div className="card-body">
                    <div className="row">
                        {accessPoints.length === 0 ? (
                            <div className="col-md-12 text-center">
                                <p>No Access Points available</p>
                            </div>
                        ) : (
                            accessPoints.map((access, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="pc-component">
                                        <div className="card mb-2 text-center" style={{ position: 'relative' }}>
                                            <span className="badge bg-warning" style={{ position: 'absolute', top: 5, left: 5 }}>{index + 1}</span>
                                            <span className="badge bg-light-primary"> <i className="ti ti-home f-50 mt-3"></i></span>

                                            <div className="card-body" onClick={async () => {

                                                setItem('selectedEntryPoint', JSON.stringify(access))
                                                navigate('/facility/visitor_access_management/home')

                                            }} style={{ cursor: 'pointer' }}>
                                                <h5 className="card-title">{access.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default AccessPoints
