import React from "react";
import { Dialog } from "primereact/dialog";

const ReusableDialog = ({ header, visible, onHide, children, submitAction }) => {
    return (
        <Dialog
            header={header}
            visible={visible}
            
            onHide={onHide}
        >
            <div className="row">
                <div className="col-md-12 mt-3">
                    {children} {/* Render dynamic content here */}
                    <div className='mt-4' style={{ float: 'right' }}>
                        <button className="btn btn-primary" onClick={submitAction}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ReusableDialog
