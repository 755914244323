import React from "react";
import { useSelector } from 'react-redux';
import {
  RouterProvider,
} from "react-router-dom";
import { router } from "./router/routes";
import 'primeicons/primeicons.css';




function App() {

  const spinner = useSelector((state) => state.authenticationReducer.spinner)
  return (
    <React.StrictMode>
      {
        spinner && <div className="page-loader">
          <div className="bar"></div>
        </div>
      }


      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
