import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Layout from '../component/layout'

function AddNewFacility() {
    const [facilityName, setFacilityName] = useState('')
    const [location, setLocation] = useState('')
    const [facilityType, setFacilityType] = useState('')
    const [zoneOrBlock, setZoneOrBlock] = useState('')
    const [zoneName, setZoneName] = useState('')
    const [numFloors, setNumFloors] = useState('')
    const [blockName, setBlockName] = useState('')
    const [blockZoneArray, setBlockZoneArray] = useState([])
    const [numZonesOrBlocks, setNumZonesOrBlocks] = useState('')
    const navigate = useNavigate()

    const handleSubmit = () => {
        // Collect facility data
        const facilityData = {
            facilityName,
            location,
            facilityType,
            numZonesOrBlocks,
            zoneName,
            numFloors,
            blockName
        }

        // Save facility data to localStorage
        let facilities = JSON.parse(localStorage.getItem('facilities')) || []
        facilities.push(facilityData)
        localStorage.setItem('facilities', JSON.stringify(facilities))

        navigate('/app/facilities')
    }

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/app/facilities"}>Facilities</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="javascript: void(0)">Add New Facility</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to="/app/facilities">
                                <i className="ti ti-arrow-left"></i>
                                &nbsp;
                                Back
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <label>Facility Name:</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                value={facilityName}
                                onChange={(e) => setFacilityName(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <label>Location:</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <label>Facility Type:</label>
                            <select
                                className="form-control mt-2"
                                value={facilityType}
                                onChange={(e) => {
                                    const value = e.target.value
                                    setFacilityType(value)
                                    setZoneOrBlock(value === 'Estate' ? 'Number of Zones:' : 'Number of Blocks:')
                                    setZoneName('') // Clear zone/block name
                                    setNumFloors('') // Clear number of floors
                                    setBlockName('') // Clear block name
                                    setNumZonesOrBlocks('') // Clear number of zones/blocks
                                }}
                            >
                                <option value="">-- Select --</option>
                                <option value="Estate">Estate</option>
                                <option value="Complex">Complex</option>
                            </select>
                        </div>
                    </div>
                    {
                        zoneOrBlock !== '' && 
                        <div className="row mt-3">
                        <div className="col-md-12">
                            <label>{zoneOrBlock}</label>
                            <input
                                type="number"
                                className="form-control mt-2"
                                value={numZonesOrBlocks}
                                onChange={(e) => {
                                    setNumZonesOrBlocks(e.target.value)

                                    const array = [];
                                    for (let i = 0; i < e.target.value; i++) {
                                        array.push(i + 1);
                                    }
                                    setBlockZoneArray(array)




                                }
                                }
                            />
                        </div>
                    </div>
                    }
                  
                    {facilityType && (
                        <>
                            {
                                blockZoneArray.map((item, index) => {
                                    return <div className="row mt-3" key={index+1}>
                                        <div className="col-md-6">
                                            <label>{facilityType === 'Estate' ? 'Zone Name:' : 'Block Name:'}</label>
                                            <input
                                                type="text"
                                                className="form-control mt-2"
                                                placeholder={facilityType === 'Estate' ? 'Zone '+parseInt(index+1):'Block '+parseInt(index+1)}
                                                value={facilityType === 'Estate' ? zoneName : blockName}
                                                onChange={(e) => facilityType === 'Estate' ? setZoneName(e.target.value) : setBlockName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Number of Floors:</label>
                                            <input
                                                type="number"
                                                className="form-control mt-2"
                                                value={numFloors}
                                                onChange={(e) => setNumFloors(e.target.value)}
                                            />
                                        </div>
                                    </div>
                            })
                        }
                        </>
                    
                    )}
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{float:"right"}}>
                                <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddNewFacility
