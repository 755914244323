
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toastify } from '../../../../utils/toast';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { visitorGuardRegistrationURL } from '../../../../utils/urls';
import { getUnitsForFacility } from '../../../../utils/urls';
import { getEntriesAndExitsForFacility } from '../../../../utils/urls';


function GuardRegistration() {
    const selectedFacilityId = getItem('selectedFacilityId');
    const facilityId = selectedFacilityId ? selectedFacilityId : null;

    const [units, setUnits] = useState([]);
    const [entryPoints, setEntryPoints] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [email,setEmail] = useState('')
    const [carRegistration, setCarRegistration] = useState('');
    const [carMake, setCarMake] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [carOccupants, setCarOccupants] = useState('');
    const [entryPoint, setEntryPoint] = useState('');


    // Fetch units for the selected facility
    const fetchUnitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setUnits(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const fetchEntriesAndExits = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setEntryPoints(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const handleGuardSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId'); 
            const guardData = {
                firstName,
                lastName,
                phoneNumber,
                idNumber,
                carRegistration,
                carMake,
                houseNumber,
                carOccupants,
                entryPoint,
                email
            };


            if (!guardData.firstName) throw new Error('First name is required.');
            if (!guardData.lastName) throw new Error('Last name is required.');
            if (!guardData.phoneNumber) throw new Error('Phone number is required.');
            if (!guardData.idNumber) throw new Error('ID number is required.');


            const response = await makeRequest2(visitorGuardRegistrationURL  + '/' + facilityId, 'POST', guardData);
            console.log("reponse",response)

            if (response.success) {
                toastify('Visitor added successfully', 'success');
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setIdNumber('');
                setCarRegistration('');
                setCarMake('');
                setHouseNumber('');
                setCarOccupants('');
                setEntryPoint('');
            }
            else {
                throw new Error(response.error);
            }

        } catch (err) {
            toastify(err.message, 'error')
        }
    }

    useEffect(() => {
        fetchUnitsForFacility();
        fetchEntriesAndExits();

    }, []);


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Guard Registration</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="card-header">
                                    <h5>Visitor Registration by Guard</h5>
                                </div>

                                <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                    <div className="row">
                                        <div className="col-lg-4">

                                        </div>

                                        <div className="col-lg-4" style={{marginTop:'30px'}}>
                                            <div className="card">
                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>First Name<span style={{ color: 'red' }}>*</span>:</label>
                                                    <input className="form-control mt-2" type="text" placeholder="First name"
                                                        value={firstName} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setFirstName(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Last Name<span style={{ color: 'red' }}>*</span>:</label>
                                                    <input className="form-control mt-2" type="text" placeholder="Last name"
                                                        value={lastName} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setLastName(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Phone Number<span style={{ color: 'red' }}>*</span>:</label>
                                                    <input className="form-control mt-2" type="text" placeholder="Phone number"
                                                        value={phoneNumber} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setPhoneNumber(value)
                                                        }}
                                                    ></input>
                                                </div>
                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Email:</label>
                                                    <input className="form-control mt-2" type="text" placeholder="Email"
                                                        value={email} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setEmail(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>ID Number<span style={{ color: 'red' }}>*</span>:</label>
                                                    <input className="form-control mt-2" type="text" placeholder="ID number"
                                                        value={idNumber} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setIdNumber(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Vehicle Reg Number</label>
                                                    <input className="form-control mt-2" type="text" placeholder="Vehicle reg no"
                                                        value={carRegistration} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setCarRegistration(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Car make</label>
                                                    <input className="form-control mt-2" type="text" placeholder="Car make"
                                                        value={carMake} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setCarMake(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Car occupants</label>
                                                    <input className="form-control mt-2" type="text" placeholder="car occupants"
                                                        value={carOccupants} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setCarOccupants(value)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>House</label>
                                                    <select
                                                        className="form-control mt-2"
                                                        value={houseNumber}
                                                        onChange={(e) => setHouseNumber(e.target.value)}
                                                    >
                                                        <option value="">Select House</option>
                                                        {units.map((unit) => (
                                                            <option key={unit._id} value={unit._id}>
                                                                {unit.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <label>Entry point</label>
                                                    <select
                                                        className="form-control mt-2"
                                                        value={entryPoint}
                                                        onChange={(e) => setEntryPoint(e.target.value)}
                                                    >
                                                        <option value="">Select Entry Point</option>
                                                        {entryPoints.map((point) => (
                                                            <option key={point._id} value={point._id}>
                                                                {point.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>


                                                <div className='mt-4 mb-2' style={{ marginRight: '5px' }}>
                                                    <button className="btn btn-primary"  style={{ float: 'right' }} onClick={handleGuardSubmit}>Submit</button>
                                                </div>


                                            </div>


                                        </div>
                                        <div className="col-lg-4">

                                        </div>

                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                </div>

                </div>
            </div>

        </Layout>
    )
}


export default GuardRegistration