import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { getItem, clearStorage } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { getGuardTime } from '../../../utils/urls';
function Nav() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate()
    const [userName, setFullname] = useState("")
    const [userRole, setRole] = useState("")

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const HandleLogOut = async () => {
        await clearStorage()
        navigate('/')
    }
    const getCurrentUser = async () => {
        try {
            const currentUserValue = await getItem('APPUSER')
            if (currentUserValue) {
                const currentUser = JSON.parse(currentUserValue)
                const fullName = currentUser.user.fullName
                const role = currentUser.user.role
                setFullname(fullName)
                setRole(role)
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }

    const fetchGuardTimes = async () => {
        try {
            const response = await makeRequest2(getGuardTime, 'GET');

            if (response.success) {
                const { startTime, endTime } = response.data;

                setStartTime(startTime);
                setEndTime(endTime);
            }


        } catch (err) {
            toastify(err.message, 'error');
        }
    };


    useEffect(() => {
        getCurrentUser()
        fetchGuardTimes()
    }, [])

    return (
        <nav className="pc-sidebar">
            <div className="navbar-wrapper">
                <div className="m-header d-flex justify-content-center">
                    <Link to={"/facility/"} className="b-brand text-primary">

                        <img
                            src="/assets/images/logo.png"
                            className="img-fluid logo-lg"
                            alt="logo"
                            style={{ width: 60 }}
                        />
                        <span className="badge bg-light-success rounded-pill ms-2 theme-version">
                            v1.0
                        </span>
                    </Link>
                </div>
                <div className="navbar-content" style={{ overflowY: 'scroll' }}>
                    <div className="card pc-user-card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        src="/assets/images/user/avatar-1.jpg"
                                        alt="user-image"
                                        className="user-avtar wid-45 rounded-circle"
                                    />
                                </div>
                                <div className="flex-grow-1 ms-3 me-2">
                                    <h6 className="mb-0">{userName}</h6>
                                </div>
                                <Link
                                    className="btn btn-icon btn-link-secondary avtar"
                                    data-bs-toggle="collapse"
                                    to="#pc_sidebar_userlink"
                                >
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-sort-outline" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                                <div className="pt-3">

                                    <Link to={"/facility/settings"}>
                                        <i className="ti ti-settings" />
                                        <span>Settings</span>
                                    </Link>

                                    <Link to={"#!"} onClick={() => { HandleLogOut() }}>
                                        <i className="ti ti-power" />
                                        <span>Logout</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="pc-navbar mb-5">
                        {/* Everyone can see these items except guards */}
                        {
                            userRole === 'admin' &&
                            <li className={`pc-item ${currentPath === '/app/facilities' ? 'active' : ''}`}>
                                <Link to={'/app/facilities'} className="pc-link">
                                    <span className="pc-micon">
                                        <svg className="pc-icon">
                                            <use xlinkHref="#custom-logout" />
                                        </svg>
                                    </span>

                                    <span className="pc-mtext">Home Page</span>


                                </Link>
                            </li>

                        }

                        {
                            userRole === 'guard' &&
                            <li className={`pc-item ${currentPath === '/app/facilities' ? 'active' : ''}`}>
                                <Link to={'/app/access_points'} className="pc-link">
                                    <span className="pc-micon">
                                        <svg className="pc-icon">
                                            <use xlinkHref="#custom-logout" />
                                        </svg>
                                    </span>

                                    <span className="pc-mtext">Home Page</span>


                                </Link>
                            </li>

                        }
                        {userRole !== 'guard' && (
                            <>

                                <li className={`pc-item ${currentPath === '/facility' ? 'active' : ''}`}>
                                    <Link to={'/facility/'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-home" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Dashboard</span>


                                    </Link>
                                </li>
                                <li className={`pc-item ${currentPath === '/facility/unit_management' ? 'active' : ''}`}>
                                    <Link to={'/facility/unit_management'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-element-plus" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Unit Management</span>


                                    </Link>
                                </li>

                                <li className={`pc-item ${currentPath === '/facility/customer_management' || currentPath === '/facility/customer_management/add_new_customer' ? 'active' : ''}`}>
                                    <Link to={'/facility/customer_management'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-profile-2user-outline" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Member Management</span>


                                    </Link>
                                </li>

                            </>
                        )}


                        <li className="pc-item pc-caption">
                            <label>Visitor & Access Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>

                        {
                            userRole === 'guard' && <li className={`pc-item ${currentPath === '/facility/visitor_access_management/home/' || currentPath === '/facility/visitor_access_management/home' ? 'active' : ''}`}>
                                <Link to={'/facility/visitor_access_management/home/'} className="pc-link">
                                    <span className="pc-micon">
                                        <svg className="pc-icon">
                                            <use xlinkHref="#custom-home" />
                                        </svg>
                                    </span>

                                    <span className="pc-mtext">Home</span>
                                </Link>
                            </li>
                        }


                        {userRole !== 'guard' && (
                            <>

                                <li className={`pc-item ${currentPath === '/facility/visitor_access_management/digital_logs/' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/digital_logs/'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-keyboard" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Digital Logs</span>
                                    </Link>
                                </li>

                                <li className={`pc-item ${currentPath === '/facility/visitor_management/visitor_list' || currentPath === '/facility/visitor_access_management/visitor_registration/visitor_list' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/visitor_registration/visitor_list'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-text-align-justify-center" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Visitor List</span>
                                    </Link>
                                </li>

                            </>
                        )}







                        {userRole === 'guard' && (

                            <>
                                <li className={`pc-item ${currentPath === '/facility/visitor_access_management/qrcode/scan_qr_code/' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/qrcode/scan_qr_code/'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-cpu-charge" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Scan QR Code</span>
                                    </Link>
                                </li>
                                <li className={`pc-item ${currentPath === '/facility/visitor_access_management/code/verify/' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/code/verify/'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-24-support" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Verify Code</span>
                                    </Link>
                                </li>
                                <li className={`pc-item ${currentPath === '/facility/visitor_management/gate_registration' || currentPath === '/facility/visitor_access_management/visitor_registration/gate_registration' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/visitor_registration/gate_registration'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-user-add" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Gate Registration</span>
                                    </Link>
                                </li>
                                <li className={`pc-item ${currentPath === '/facility/visitor_access_management/waiting_list' || currentPath === '/facility/visitor_access_management/waiting_list' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/waiting_list'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-flag" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Waiting List</span>
                                    </Link>
                                </li>
                            </>
                        )}


                        {userRole !== 'guard' && (

                            <>
                                <li className={`pc-item ${currentPath === '/facility/visitor_access_management/guard_registration' || currentPath === '/facility/visitor_access_management/guard_registration/register_guard' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/guard_registration'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-security-safe" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Guard Management</span>
                                    </Link>
                                </li>


                                <li className={`pc-item ${currentPath === '/facility/visitor_access_management/entries_and_exits' || currentPath === '/facility/visitor_access_management/entries_and_exits/add_entry_and_exit' ? 'active' : ''}`}>
                                    <Link to={'/facility/visitor_access_management/entries_and_exits'} className="pc-link">
                                        <span className="pc-micon">
                                            <svg className="pc-icon">
                                                <use xlinkHref="#custom-data" />
                                            </svg>
                                        </span>

                                        <span className="pc-mtext">Entries & Exits</span>
                                    </Link>
                                </li>
                            </>
                        )}

                    </ul>
                </div>
            </div>
        </nav >

    )
}
export default Nav