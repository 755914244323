import React, { useState, } from 'react'
import { Link } from 'react-router-dom'
// import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { EntryAndExitURL } from '../../../../utils/urls';

function AddEntryAndExit() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [location, setLocation] = useState("");
    const [purpose, setPurpose] = useState("");
    const [range,setRange ] = useState(0)



    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const handleAccessSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const accessData = {
                name,
                location,
                purpose,
                longitude,
                latitude,
                range
            };

            if (!accessData.name) throw new Error('Name is required.');
            if (!accessData.location) throw new Error('Location is required.');
            if (!accessData.purpose) throw new Error('Purpose is required.');
            if (!accessData.longitude) throw new Error('Longitude is required.');
            if (!accessData.latitude) throw new Error('Latitude is required.');
            if (!accessData.range) throw new Error('Range is required.');


            const response = await makeRequest2(EntryAndExitURL + '/' + facilityId, 'POST', accessData);


            if (response.success) {
                toastify('Entry/Exit point added successfully', 'success');
                setName('');
                setLocation('');
                setPurpose('');
                setLatitude('');
                setLongitude('');
                setRange(0)
                navigate('/facility/visitor_access_management/entries_and_exits');
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const handleAccessClear = () => {
        setName('');
        setLocation('');
        setPurpose('');
        setLatitude('');
        setLongitude('');
        setRange(0)
    };

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/app/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#">Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#">Add Entry and Exit</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card mb-4">
                        <div className="card-header">
                            <Link to="/facility/visitor_access_management/entries_and_exits">
                                <span><i className="ti ti-arrow-narrow-left"></i> Back</span>
                            </Link>
                        </div>

                           

                            <div className="card">


                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card-body">
                                            <div className="container">

                                                <div className="row">
                                                    <div className="card">


                                                        <div className="card-body">
                                                            <div className='container'>
                                                                <div className='row'>
                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Entry Name<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                value={name}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setName(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">
                                                                                Location <span style={{ color: 'red' }}>*</span>
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter location manually"
                                                                                value={location}
                                                                                autoComplete="address-level2"
                                                                                onChange={(e) => {
                                                                                    setLocation(e.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Purpose<span style={{ color: 'red' }}>*</span></label>
                                                                            <select
                                                                                className="form-control mt-2"
                                                                                value={purpose}
                                                                                onChange={(e) => setPurpose(e.target.value)}
                                                                            >
                                                                                <option value="">--select--</option>
                                                                                <option value="entry/exit">Entry / Exit</option>
                                                                                <option value="entry">Entry</option>
                                                                                <option value="exit">Exit</option>
                                                                                <option value="emergency exit">Emergency Exit</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <div className="mb-3">
                                                                                    <label className="form-label">
                                                                                        Latitude <span style={{ color: 'red' }}>*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Click the button to get latitude"
                                                                                        value={latitude}
                                                                                        onChange={(e) => setLatitude(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                                </div>

                                                                                <div className="col-sm-3">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">
                                                                                            Longitude <span style={{ color: 'red' }}>*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Click the button to get longitude"
                                                                                            value={longitude}
                                                                                            onChange={(e) => setLongitude(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">
                                                                                        Radius ( Metres ) <span style={{ color: 'red' }}>*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder="Range"
                                                                                            value={range}
                                                                                            onChange={(e) => setRange(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-sm-3 pt-4">
                                                                                    <button
                                                                                        className="btn btn-primary"
                                                                                        onClick={() => {
                                                                                            if (navigator.geolocation) {
                                                                                                navigator.geolocation.getCurrentPosition(
                                                                                                    (position) => {
                                                                                                        const { latitude, longitude } = position.coords;
                                                                                                        setLatitude(latitude);
                                                                                                        setLongitude(longitude);
                                                                                                    },
                                                                                                    (error) => {
                                                                                                        toastify.error("Error retrieving location: " + error.message);
                                                                                                        setLatitude("Unable to retrieve latitude");
                                                                                                        setLongitude("Unable to retrieve longitude");
                                                                                                    }
                                                                                                );
                                                                                            } else {
                                                                                                toastify.error("Geolocation is not supported by this browser.");
                                                                                                setLatitude("Geolocation not supported");
                                                                                                setLongitude("Geolocation not supported");
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Get coordinates
                                                                                    </button>
                                                                                </div>

                                                                           
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="text-end mb-4">
                                                                <button type="button" className="btn btn-outline-secondary" onClick={handleAccessClear}>Clear</button>
                                                                &nbsp;
                                                                <button type="button" className="btn btn-primary" onClick={handleAccessSubmit}>Submit</button>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           

        </Layout >
    )
}

export default AddEntryAndExit;
