const formatISODate = (isoDate)=> {
    const date = new Date(isoDate);
    return date.toLocaleString('en-US', {
      weekday: 'long', // e.g., Saturday
      year: 'numeric', // e.g., 2024
      month: 'long',   // e.g., September
      day: 'numeric',  // e.g., 28
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,    // For AM/PM format
      timeZone: 'UTC'  // Converts time to UTC (you can change this to your preferred time zone)
    });
  }

  module.exports = formatISODate