import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout';
import { Link } from 'react-router-dom';
import { Button, InputText } from 'primereact';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { addLevy, getLevyTypes } from '../../../../utils/urls';

function AddContract() {
    const navigate = useNavigate();

    // State variables
    const [levyName, setLevyName] = useState('');
    const [levyType, setLevyType] = useState('');
    const[levyAmount,setLevyAmount] =useState('');
    const [levyApplicant, setLevyApplicant] = useState('');
    const [collectionFrequency, setCollectionFrequency] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');

    const [levyTypes, setLevyTypes] = useState([]);


    const fetchLevyTypes = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getLevyTypes + '/' + facilityId, 'GET', {});
            
            if (response.success) {
                setLevyTypes(response.data);
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleLevySubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId'); // Ensure this is awaited

            const levyData = {
                levyName,
                levyType,
                levyAmount,
                levyApplicant,
                collectionFrequency,
                invoiceDate,
            };


            if (!levyData.levyName) throw new Error('Contract name is required.');
            if (!levyData.levyType) throw new Error('Phone number is required.');
            if (!levyData.levyAmount) throw new Error('Amount is required.');
            if (!levyData.levyApplicant) throw new Error('Start date is required.');
            if (!levyData.collectionFrequency) throw new Error('End date is required.');
            if (!levyData.invoiceDate) throw new Error('End date is required.');


            const response = await makeRequest2(addLevy + '/' + facilityId, 'POST', levyData);

            if (response.success) {
                toastify('Levy contract added successfully', 'success');
                setLevyName('');
                setLevyType('');
                setLevyAmount('')
                setLevyApplicant('');
                setCollectionFrequency('');
                setInvoiceDate('');
            }
            else {
                throw new Error(response.error);
            }
        }
        catch(err) {
            toastify(err.message, 'error')
        }
    }

       const handleClear = () => {
        setLevyName('');
        setLevyType('');
        setLevyAmount('')
        setLevyApplicant('');
        setCollectionFrequency('');
        setInvoiceDate('');
       };


       useEffect(() => {
        fetchLevyTypes();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/levy_management/levy_management/levy_management"}>levy Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Add New Levy
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                    </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>Add New Levy</h5>
                        </div>
                        <div className='card-body'>
                            <div className='container'>
                                <div className='row'>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Name<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                className="form-control"
                                                value={levyName}
                                                onChange={(e) => setLevyName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Type<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control mt-2"
                                                value={levyType}
                                                onChange={(e) => setLevyType(e.target.value)}
                                            >
                                                 <option value="">-- Select --</option>
                                                {levyTypes.map((type) => (
                                                    <option key={type._id} value={type._id}>
                                                        {type.levyType}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Amount<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                className="form-control"
                                                value={levyAmount}
                                                onChange={(e) => setLevyAmount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Collection Frequency<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control mt-2"
                                                value={collectionFrequency}
                                                onChange={(e) => setCollectionFrequency(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Semi-anually">Semi-anually</option>
                                                <option value="Annually">Annually</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Applicant<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control mt-2"
                                                value={levyApplicant}
                                                onChange={(e) => setLevyApplicant(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="home owner">Home Owner</option>
                                                <option value="tenant">Tenant</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Invoice Date<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                type="date"
                                                className="form-control"
                                                value={invoiceDate}
                                                onChange={(e) => setInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>

                            <div className="text-end mt-4">
                            <Button type="button" className="btn btn-outline-secondary" onClick={handleClear}>clear</Button>
                            &nbsp;
                            <Button type="button" className="btn btn-primary" onClick={handleLevySubmit}>Submit</Button>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default AddContract;
