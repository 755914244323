import React from "react";
import Layout from "../component/layout";



function StockAndSpareParts () {


    return(
        <Layout>

        </Layout>
    )
}

export default StockAndSpareParts