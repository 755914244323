import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getUnitsForFacility } from '../../../utils/urls';
import { getItem } from '../../../utils/localStorage'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';


function UnitManagement() {
    const navigate = useNavigate()

    const [units, setUnits] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch units for the selected facility
    const fetchUnitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setUnits(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };
    
    const handleInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchUnitsForFacility()
            } else {
                const results = await filterObjectsByAllProperties(units, value);
                setUnits(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const handleUnitClick = (rowData) => {
        return (
            <Link to={'/facility/unit_management/view_unit/' + rowData._id} style={{ color: 'royalblue' }}>
                {rowData.name}
            </Link>
        )
    }


    useEffect(() => {

        fetchUnitsForFacility();

    }, []);


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/unit_management"}>Unit Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Units</h5>

                                </div>
                                <div className='card-body'>
                                    <div className="row">

                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="row">
                                                        <div className='col-md-4'>
                                                            <div className="form-search">
                                                                <i className="ti ti-search"></i>
                                                                <input type="search" className="form-control" placeholder="Search Here" value={searchTerm} onChange={(e) => {
                                                                    handleInputSearch(e.target.value)
                                                                }} />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="dt-responsive table-responsive mt-4">
                                                                <DataTable value={units} emptyMessage="No Units found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                    <Column header="Name" body={handleUnitClick}></Column>
                                                                    <Column field="unitType" header="Type"></Column>
                                                                    <Column field="division" header="Division"></Column>
                                                                    <Column field="floorUnitNo" header="Floor/Unit"></Column>
                                                                    <Column field="lettableFloorArea" header="LFA"></Column>
                                                                    <Column field="landRateNumber" header="LRN"></Column>
                                                                    <Column field="grossArea" header="GA"></Column>
                                                                    <Column field="netLettableArea" header="NLA"></Column>

                                                                </DataTable>


                                                            </div>
                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default UnitManagement

// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { Button } from 'primereact/button';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import 'primereact/resources/themes/lara-light-blue/theme.css';
// import Layout from '../component/layout';
// import { toastify } from '../../../utils/toast';
// import { makeRequest2 } from '../../../utils/makeRequest';
// import { getUnitsForFacility } from '../../../utils/urls';
// import { getItem } from '../../../utils/localStorage';
// import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

// function UnitManagement() {
//   const navigate = useNavigate();

//   const [units, setUnits] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   const fetchUnitsForFacility = async () => {
//     try {
//       const value = await getItem('selectedFacilityId');
//       const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});

//       if (response.success) {
//         setUnits(response.data); // Set fetched units to state
//       } else {
//         throw new Error('Failed to fetch units');
//       }
//     } catch (error) {
//       console.log(error.message);
//       toastify(error.message, 'error');
//     }
//   };

//   const handleInputSearch = async (value) => {
//     try {
//       setSearchTerm(value);
//       if (value === '') {
//         fetchUnitsForFacility();
//       } else {
//         const results = await filterObjectsByAllProperties(units, value);
//         setUnits(results);
//       }
//     } catch (error) {
//       toastify(error.message, 'error');
//     }
//   };

//   const handleUnitClick = (rowData) => {
//     return (
//       <Link to={'/facility/unit_management/view_unit/' + rowData._id} style={{ color: 'royalblue' }}>
//         {rowData.name}
//       </Link>
//     );
//   };

//   useEffect(() => {
//     fetchUnitsForFacility();
//   }, []);

//   const header = (
//     <div className="flex align-items-center justify-content-end gap-2">
//       <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
//       <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
//       <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
//     </div>
//   );

//   return (
//     <Layout>
//       <div className="page-header">
//         <div className="page-block">
//           <div className="row align-items-center">
//             <div className="col-md-12">
//               <ul className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to={"/facility/"}>Dashboard</Link>
//                 </li>
//                 <li className="breadcrumb-item">
//                   <Link to={"/facility/unit_management"}>Unit Management</Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="row">
//         <div className="col-sm-12">
//           {/* Meter Management Section */}
//           <div className="card">
//             <div className="card-header">
//               <h5>Meter Management</h5>
//             </div>
//             <div className="card-body">
//               <DataTable value={units} header={header} emptyMessage="No Meters found." paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
//                 <Column header="Meter Serial No" field="serialNo" />
//                 <Column header="Current Reading" field="currentReading" />
//                 <Column header="Location" field="location" />
//                 <Column header="GPS" field="gpsLocation" />
//                 <Column header="Actions" body={(rowData) => (
//                   <div>
//                     <Button label="Open" icon="pi pi-lock-open" className="p-button-sm p-button-success" />
//                     <Button label="Close" icon="pi pi-lock" className="p-button-sm p-button-danger" />
//                   </div>
//                 )} />
//               </DataTable>
//             </div>
//           </div>

//           {/* Invoice / Billing Section */}
//           <div className="card">
//             <div className="card-header">
//               <h5>Invoice / Billing</h5>
//             </div>
//             <div className="card-body">
//               <div className="row">
//                 <div className="col-md-6">
//                   <h6>Billing Cycles</h6>
//                   <label>Schedule Sending of Invoice</label>
//                   <input type="datetime-local" className="form-control" />
//                   <select className="form-control mt-2">
//                     <option>Monthly</option>
//                     <option>Weekly</option>
//                     <option>Manual</option>
//                   </select>
//                 </div>
//                 <div className="col-md-6">
//                   <h6>Bill List</h6>
//                   <DataTable value={units} header="Invoices" paginator rows={5} stripedRows>
//                     <Column header="Client" field="client" />
//                     <Column header="Status" field="status" />
//                     <Column header="Payment Status" field="paymentStatus" />
//                     <Column header="Actions" body={(rowData) => (
//                       <div>
//                         <Button label="Download" icon="pi pi-download" className="p-button-sm p-button-info" />
//                         <Button label="Send Email" icon="pi pi-envelope" className="p-button-sm p-button-secondary" />
//                       </div>
//                     )} />
//                   </DataTable>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Reports Section */}
//           <div className="card">
//             <div className="card-header">
//               <h5>Reports</h5>
//             </div>
//             <div className="card-body">
//               <h6>Revenue Reports</h6>
//               <DataTable value={units} header="Revenue Summary" paginator rows={5} stripedRows>
//                 <Column header="Client" field="client" />
//                 <Column header="Total Revenue" field="totalRevenue" />
//               </DataTable>

//               <h6>Consumption Reports</h6>
//               <DataTable value={units} header="Consumption Summary" paginator rows={5} stripedRows>
//                 <Column header="Client" field="client" />
//                 <Column header="Total Consumption" field="totalConsumption" />
//               </DataTable>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Layout>
//   );
// }

// export default UnitManagement;
