import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Dialog } from 'primereact/dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { filterObjectsByAllProperties } from '../../../../utils/filterSearch';
import ConfirmDialog from '../../component/confirmDialog';
import {
  getEntriesAndExitsForFacility,
  editEntriesAndExitsForFacility,
  deleteEntriesAndExitsForFacility,
  disableEntriesAndExitsForFacility
} from '../../../../utils/urls';

function EntriesAndExits() {
  const navigate = useNavigate()
  const [accessId, setAccessId] = useState(null); // State to store the accessId


  const [entryExit, setEntryExit] = useState('')
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [purpose, setPurpose] = useState('');
  const [range, setRange] = useState(0)

  const [searchTerm, setSearchTerm] = useState('');
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [selectedEntryExitId, setSelectedEntryExitId] = useState(null);

  const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status


  const fetchEntriesAndExitsForFacility = async () => {
    try {
      const value = await getItem('selectedFacilityId')
      const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

      if (response.success) {
        setEntryExit(response.data);
      } else {
        throw new Error('Failed to fetch entries and exits')
      }
    } catch (error) {
      console.log(error.message)
      toastify(error.message, 'error');
    }
  };

  const actionTemplate = (rowData) => {
    const isDisabled = rowData.disabled; // Check if the row is disabled

    return (
      <div className="actions">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
          disabled={isDisabled}  // Disable edit button if the row is disabled
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            setSelectedEntryExitId(rowData._id);
            setConfirmAction('delete');  // Set action to delete
            setConfirmDialogVisible(true);
          }}
          tooltip="Delete"
          disabled={!isDisabled}  // Allow deletion only if the row is disabled
        />
        <Button
          icon={`ti ${isDisabled ? 'ti-lock-open' : 'ti-lock'}`}  // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setSelectedEntryExitId(rowData._id);
            setConfirmAction(isDisabled ? 'enable' : 'disable');  // Set action to enable/disable
            setSelectedDisableStatus(isDisabled);
            setConfirmDialogVisible(true);
          }}
          tooltip={isDisabled ? "Enable" : "Disable"}  // Fixed dynamic tooltip
        />
      </div>



    );
  };


  const handleEdit = (rowData) => {
    setAccessId(rowData._id);
    setName(rowData.name);
    setLocation(rowData.location);
    setPurpose(rowData.purpose);
    setSelectedRowData(rowData);
    setEditDialogVisible(true);  // Show the edit dialog
  };


  const HandleEditSubmit = async () => {
    try {
      const updatedData = {
        name,
        location,
        purpose,
        range
      };

      const response = await makeRequest2(`${editEntriesAndExitsForFacility}/${accessId}`, 'POST', updatedData);

      if (response.success) {
        toastify(response.data, 'success');
        setName('');
        setLocation('');
        setPurpose('');
        setRange(0)
        setEditDialogVisible(false);
        fetchEntriesAndExitsForFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, 'error');
    }
  };

  const handleDelete = async (accessId) => {
    try {
      const response = await makeRequest2(deleteEntriesAndExitsForFacility + '/' + accessId, 'DELETE');

      if (response.success) {
        toastify(response.data, 'success');
        fetchEntriesAndExitsForFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }

  };


  const handleDisable = async (accessId, currentStatus) => {
    const action = currentStatus ? 'enable' : 'disable';

    try {
      const newDisabledStatus = !currentStatus;

      const response = await makeRequest2(
        `${disableEntriesAndExitsForFacility}/${accessId}`,
        'PATCH',
        { disabled: newDisabledStatus }
      );

      if (response.success) {
        toastify(response.data, 'success');
        fetchEntriesAndExitsForFacility();  // Refresh the data
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };

  const statusTemplate = (rowData) => {
    const isDisabled = rowData.disabled;

    return (
      <span
        style={{
          fontWeight: 'bold',
          color: isDisabled ? '#ff4d4d' : '#4caf50',  // Red for Disabled, Green for Enabled
          padding: '5px 10px',
          borderRadius: '15px',
          backgroundColor: isDisabled ? '#ffebeb' : '#e8f5e9'  // Light red or green background
        }}
      >
        {isDisabled ? 'Disabled' : 'Enabled'}
      </span>
    );
  };




  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchEntriesAndExitsForFacility()
      } else {
        const results = await filterObjectsByAllProperties(entryExit, value);
        setEntryExit(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };

  const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);

    if (confirmAction === 'delete') {
      // Handle delete logic here
      await handleDelete(selectedEntryExitId);
    } else if (confirmAction === 'enable' || confirmAction === 'disable') {
      // Handle enable/disable logic here
      await handleDisable(selectedEntryExitId, selectedDisableStatus);
    }
  };



  useEffect(() => {

    fetchEntriesAndExitsForFacility();

  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/app/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor & Access Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Entries and Exits</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Entry and Exit Points</h5>
                </div>
                <div className='card-body'>
                  <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                      <div className="card">
                        <div className="card-body position-relative">
                          <div className="row">
                            <div className="col-md-3 col-xs-12 mb-3">
                              <input
                                className="form-control"
                                placeholder="Search here"
                                value={searchTerm}
                                onChange={(e) => { handleInputSearch(e.target.value) }}
                              />
                            </div>

                            <div className="col-md-9 col-xs-12 mb-3">
                              <div style={{ float: 'right' }}>
                                <button className='btn btn-primary' onClick={() => navigate('/facility/visitor_access_management/entries_and_exits/add_entry_and_exit')}>Add Entry / Exit Point</button>
                                &nbsp;
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="dt-responsive table-responsive">
                                <DataTable
                                  value={entryExit}
                                  emptyMessage="No Data Found."
                                  sortMode="multiple"
                                  paginator
                                  rows={10}
                                  stripedRows
                                  tableStyle={{ minWidth: '50rem' }}
                                >
                                  <Column field="name" header="Name" />
                                  <Column field="location" header="location" />
                                  <Column field="purpose" header="Purpose" />
                                  <Column field="range" header="Radius" />
                                  <Column body={statusTemplate} header="Status" />
                                  <Column body={actionTemplate} header="Actions" />
                                </DataTable>
                                {/* Confirm dialog */}
                                <ConfirmDialog
                                  visible={isConfirmDialogVisible}
                                  onHide={() => setConfirmDialogVisible(false)}
                                  message={`Are you sure you want to ${confirmAction} this access point?`}
                                  onConfirm={handleConfirmAction}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog header={`Edit Entry / Exit Point`} visible={editDialogVisible}  onHide={() => { if (!editDialogVisible) return; setEditDialogVisible(false); }}>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className='mb-4'>
              <label>Name:</label>
              <input className="form-control mt-2" type="text" placeholder="Name" value={name} onChange={(e) => {
                const value = e.target.value;
                setName(value)

              }}></input>
            </div>

            <div className='mb-4'>
              <label>Location:</label>
              <input className="form-control mt-2" type="text" placeholder="Location" value={location} onChange={(e) => {
                const value = e.target.value;
                setLocation(value)

              }}></input>
            </div>

            <div className='mb-4'>
              <label>Purpose:</label>
              <select
                className="form-control mt-2"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              >
                <option value="">--select--</option>
                <option value="entry/exit">Entry / Exit</option>
                <option value="entry">Entry</option>
                <option value="exit">Exit</option>
                <option value="emergency exit">Emergency Exit</option>
              </select>

            </div>
            <div className="col-sm-12">
              <div className="mb-3">
                <label className="form-label">
                  Radius ( Metres )<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Range"
                  value={range}
                  onChange={(e) => setRange(e.target.value)}
                />
              </div>
            </div>

            <div className='mt-4' style={{ float: 'right' }}>
              <button className="btn btn-primary" onClick={HandleEditSubmit}>Submit</button>
            </div>

          </div>

        </div>

      </Dialog>

    </Layout>
  )
}


export default EntriesAndExits