import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout';
import ConfirmDialog from '../../component/confirmDialog';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { deleteContract, getContracts, disableContract } from '../../../../utils/urls';

function LevyContract() {
    const navigate = useNavigate();

    const [contracts, setContracts] = useState([]);
    const [filteredContracts, setFilteredContracts] = useState([]); // For storing filtered contracts
    const [searchTerm, setSearchTerm] = useState(''); // For tracking the search input

    const [contractId, setContractId] = useState(null); 
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
    const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status

    const [selectedContractId, setSelectedContractId] = useState(null);

    const fetchContracts = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getContracts + '/' + facilityId, 'GET', {});
            
            if (response.success) {
                setContracts(response.data);
                setFilteredContracts(response.data); // Initialize filtered contracts with full data
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    // Function to handle search input
    const handleInputSearch = (value) => {
        setSearchTerm(value);
        if (value === '') {
            setFilteredContracts(contracts); // If search is cleared, reset to full list
        } else {
            const filtered = contracts.filter((contract) =>
                Object.values(contract).some((field) =>
                    String(field).toLowerCase().includes(value.toLowerCase())
                )
            );
            setFilteredContracts(filtered); // Update filtered contracts based on search term
        }
    };


    const actionBodyTemplate = (rowData) => {
        const isDisabled = rowData.disabled; // Check if the row is disabled
    
        return (
          <div className="actions">
            <Button
              icon="ti ti-trash"
              className="p-button-rounded p-button-danger mr-2"
              onClick={() => {
                setSelectedContractId(rowData._id);
                setConfirmAction('delete');  // Set action to delete
                setConfirmDialogVisible(true);
              }}
              tooltip="Delete"
              disabled={!isDisabled}  // Allow deletion only if the row is disabled
            />
            <Button
              icon={`ti ${isDisabled ? 'ti-lock-open' : 'ti-lock'}`}  // Fixed template literal for dynamic icon
              className="p-button-rounded p-button-warning"
              onClick={() => {
                setSelectedContractId(rowData._id);
                setConfirmAction(isDisabled ? 'enable' : 'disable');  // Set action to enable/disable
                setSelectedDisableStatus(isDisabled);
                setConfirmDialogVisible(true);
              }}
              tooltip={isDisabled ? "Enable" : "Disable"}  // Fixed dynamic tooltip
            />
          </div>
    
    
    
        );
    };

    const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);

    if (confirmAction === 'delete') {
        // Handle delete logic here
        await handleDelete(selectedContractId);
    } else if (confirmAction === 'enable' || confirmAction === 'disable') {
        // Handle enable/disable logic here
        await handleDisable(selectedContractId, selectedDisableStatus);
    }
    };

    const handleDelete = async (contractId) => {
        try {
          const response = await makeRequest2(deleteContract + '/' + contractId, 'DELETE');
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchContracts();
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    
    };
    
      
    const handleDisable = async (contractId, currentStatus) => {
    const action = currentStatus ? 'enable' : 'disable';

    try {
        const newDisabledStatus = !currentStatus;

        const response = await makeRequest2(
        `${disableContract}/${contractId}`,
        'PATCH',
        { disabled: newDisabledStatus }
        );

        if (response.success) {
        toastify(response.data, 'success');
        fetchContracts();  // Refresh the data
        } else {
        throw new Error(response.error);
        }
    } catch (err) {
        toastify(err.message, 'error');
    }
    };

    const statusTemplate = (rowData) => {
        const isDisabled = rowData.disabled;
    
        return (
          <span
            style={{
              fontWeight: 'bold',
              color: isDisabled ? '#ff4d4d' : '#4caf50',  // Red for Disabled, Green for Enabled
              padding: '5px 10px',
              borderRadius: '15px',
              backgroundColor: isDisabled ? '#ffebeb' : '#e8f5e9'  // Light red or green background
            }}
          >
            {isDisabled ? 'Disabled' : 'Enabled'}
          </span>
        );
    };


    const handleContractClick = (rowData) => {
        const contractName = rowData.contractName;
        return (
          <Link to={'/facility/levy_management/levy_contract/view_contract/' + rowData._id} style={{ color: 'royalblue' }}>
            {contractName}
          </Link>
        );
      };

    useEffect(() => {
        fetchContracts();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/levy_management/levy_contract/levy_contract/"}>Levy Contract</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Levy Contract Details</h5>
                                </div>
                                <div className='card-body'>
                                    <div className="row">
                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="row">
                                                        <div className="col-md-3 col-xs-12 mb-3">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Search here"
                                                                value={searchTerm}
                                                                onChange={(e) => handleInputSearch(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-9 col-xs-12 mb-3">
                                                            <div style={{ float: 'right' }}>
                                                                <Button className='btn btn-primary' onClick={() => navigate('/facility/levy_management/levy_contract/add_contract')}>
                                                                    Add Contract
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="dt-responsive table-responsive">
                                                                <DataTable
                                                                    value={filteredContracts}
                                                                    emptyMessage="No contracts found."
                                                                    sortMode="multiple"
                                                                    paginator
                                                                    rows={5}
                                                                    stripedRows
                                                                    tableStyle={{ minWidth: '50rem' }}
                                                                >
                                                                    <Column field="contractName" header="Name" body={handleContractClick} />
                                                                    <Column field="contractStart" header="Starting Date"/>
                                                                    <Column field="contractEnd" header="Ending Date"/>
                                                                    <Column body={statusTemplate} header="Status" />
                                                                    <Column body={actionBodyTemplate} header="Actions" />
                                                                </DataTable>
                                                                {/* Confirm dialog */}
                                                                <ConfirmDialog
                                                                visible={isConfirmDialogVisible}
                                                                onHide={() => setConfirmDialogVisible(false)}
                                                                message={`Are you sure you want to ${confirmAction} this contract?`}
                                                                onConfirm={handleConfirmAction}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LevyContract;
