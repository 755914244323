import React, { useState, useMemo } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link, useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout';

function CampaignManagement() {
    const [form, setForm] = useState({
        name: '',
        channelMode: '',
        facility: '',
        date: '',
        time: '',
        behaviour: '',
        messageTemplate: '',
        message: ''
    });
    const [campaigns, setCampaigns] = useState([]);
    const [error, setError] = useState('');
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const navigate = useNavigate();

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const validateForm = () => {
        const { name, channelMode, facility, date, time, behaviour, messageTemplate, message } = form;
        if (!name || !channelMode || !facility || !date || !time || !behaviour || !messageTemplate || !message) {
            setError('Please fill all the fields.');
            return false;
        }
        setError('');
        return true;
    };

    const handleAddCampaign = () => {
        if (validateForm()) {
            // Add the new campaign to the list
            setCampaigns([...campaigns, form]);

            // Clear the form
            setForm({
                name: '',
                channelMode: '',
                facility: '',
                date: '',
                time: '',
                behaviour: '',
                messageTemplate: '',
                message: ''
            });

            // Redirect to the List of Campaigns page
            navigate('/app/campaign_management'); // Ensure this path is correct and matches your routing configuration
        } else {
            console.log('Form validation failed');
        }
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter campaigns based on search query
    const filteredCampaigns = useMemo(() => {
        return campaigns.filter(campaign => {
            return Object.values(campaign).some(value =>
                value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
    }, [campaigns, searchQuery]);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/app/campaign_management"}>Campaign Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body py-0">
                                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                <i className="ti ti-list-check me-2"></i>List of Campaigns
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                <i className="ti ti-plus me-2"></i>Add New Campaign
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                <i className="ti ti-message-2 me-2"></i>Message Template
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-xxl-12">
                                                            <div className="card">
                                                                <div className="card-body position-relative">
                                                                    <div className="col-md-3 col-xs-12 mb-3">
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="search here"
                                                                            value={searchQuery}
                                                                            onChange={handleSearchChange} // Handle search input change
                                                                        />
                                                                    </div>
                                                                    <div className="dt-responsive table-responsive">
                                                                        <DataTable
                                                                            value={filteredCampaigns} // Use filtered campaigns
                                                                            header={header}
                                                                            emptyMessage="No campaigns found."
                                                                            sortMode="multiple"
                                                                            paginator
                                                                            rows={5}
                                                                            stripedRows
                                                                            tableStyle={{ minWidth: '50rem' }}
                                                                        >
                                                                            <Column field="name" header="Name"></Column>
                                                                            <Column field="channelMode" header="Channel Mode"></Column>
                                                                            <Column field="message" header="Message"></Column>
                                                                            <Column field="facility" header="Facility"></Column>
                                                                            <Column field="date" header="Scheduled Date"></Column>
                                                                            <Column field="time" header="Scheduled Time"></Column>
                                                                            <Column field="behaviour" header="Behaviour"></Column>
                                                                        </DataTable>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Campaign Name</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="name"
                                                                                    value={form.name}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Channel Mode</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="channelMode"
                                                                                    value={form.channelMode}
                                                                                    onChange={handleInputChange}
                                                                                >
                                                                                    <option value="">-- Select --</option>
                                                                                    <option value="Email">Email</option>
                                                                                    <option value="SMS">SMS</option>
                                                                                    <option value="Whatsapp">Whatsapp</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Facility</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="facility"
                                                                                    value={form.facility}
                                                                                    onChange={handleInputChange}
                                                                                >
                                                                                    <option value="">-- Select --</option>
                                                                                    <option value="Knights Bridge">Knights Bridge</option>
                                                                                    <option value="Toll">Toll</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Scheduled Date</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    name="date"
                                                                                    value={form.date}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Scheduled Time</label>
                                                                                <input
                                                                                    type="time"
                                                                                    className="form-control"
                                                                                    name="time"
                                                                                    value={form.time}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Behaviour</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="behaviour"
                                                                                    value={form.behaviour}
                                                                                    onChange={handleInputChange}
                                                                                >
                                                                                    <option value="">-- Select --</option>
                                                                                    <option value="Marketing">Marketing</option>
                                                                                    <option value="Notification">Notification</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Message Template</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="messageTemplate"
                                                                                    value={form.messageTemplate}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Message</label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    rows="4"
                                                                                    name="message"
                                                                                    value={form.message}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {error && <p className="text-danger">{error}</p>}
                                                                    <Button label="Add Campaign" onClick={handleAddCampaign} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-5">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <p>Message Template Section Content</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CampaignManagement;
