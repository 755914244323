import React from "react"
import Layout from '../component/layout'

function ServiceVendor () {
    

    return(
        <Layout>
            
        </Layout>
    )
}

export default ServiceVendor