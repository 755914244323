import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { inputLoginEmail, inputLoginPassword, updateSpinner } from '../../features/authentication/authenticationReducer'
import { getItem, removeItem } from '../../utils/localStorage'
import { makeRequest } from '../../utils/makeRequest';
import { toastify } from '../../utils/toast';
import { checkEmailAndPasswordURL } from '../../utils/urls';



function Login() {


  const loginEmail = useSelector((state) => state.authenticationReducer.loginEmail);
  const loginPassword = useSelector((state) => state.authenticationReducer.loginPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const HandleLogin = async () => {

    try {
      dispatch(updateSpinner(true))
      if (loginEmail === '') {
        toastify('Email is required', 'error')
        dispatch(updateSpinner(false))
      }
      else if (loginPassword === '') {
        toastify('Password is required', 'error')
        dispatch(updateSpinner(false))
      }
      const body = {
        email: loginEmail,
        password: loginPassword,
        platform: 'App'
      }
      const response = await makeRequest(checkEmailAndPasswordURL, 'POST', body)
      if (response.success) {
        dispatch(updateSpinner(false))
        navigate('/code_verification')
      }
      else {
        toastify(response.error, 'error')
        dispatch(updateSpinner(false))
      }
    }
    catch (err) {
      toastify(err.message, 'error')
      dispatch(updateSpinner(false))
    }
  }
  const checkUserExistInLocalStorage = async () => {
    const userExist = await getItem('APPUSER')

    if (userExist) {
      navigate('/app/facilities')
      
    }
    else {
      removeItem('APPUSER')
      dispatch(inputLoginEmail(''))
      dispatch(inputLoginPassword(''))
    }
  }

  useEffect(() => {
    checkUserExistInLocalStorage()
  }, [])
  return (
    <>

      <div className="auth-main">
        <div className="auth-wrapper v2">
          <div className="auth-sidecontent">
            <img src="/assets/images/authentication/img-auth-sideimg.jpg" alt="images" className="img-fluid img-auth-side" />
          </div>
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <div className="text-center">
                  <Link to={'#'}>
                    <img src="/assets/images/logo.png" alt="img" style={{ width: 150 }} /></Link>
                  <div className="d-grid my-3">

                    {/* <button type="button" className="btn mt-2 btn-light-primary bg-light text-muted">
                    <img src="/assets/images/authentication/google.svg" alt="img" /> <span> Sign In with Google</span>
                  </button> */}
                  </div>
                </div>


                <h3 className="text-center f-w-500 mb-3"><b>Login</b></h3>
                <div className="mb-3">
                  <input type="email" className="form-control" id="floatingInput" value={loginEmail} onChange={(e) => {
                    dispatch(inputLoginEmail(e.target.value));
                  }} placeholder="Email Address" />
                </div>
                <div className="mb-3">
                  <input type="password" className="form-control" id="floatingInput1" value={loginPassword} onChange={(e) => {
                    dispatch(inputLoginPassword(e.target.value));
                  }} placeholder="Password" />
                </div>
                <div className="d-flex mt-1 justify-content-between align-items-center">

                  <h6 className="text-secondary f-w-400 mb-0">
                    <Link to={'/forgot_password'}>
                      Forgot Password?
                    </Link>
                  </h6>
                </div>
                <div className="d-grid mt-4">
                  <button type="button" className="btn btn-primary" onClick={HandleLogin}>Login</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login