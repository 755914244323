import { createSlice } from '@reduxjs/toolkit'

export const coreSlice = createSlice({
  name: 'App',
  initialState: {
    currentUser: {},
    token: "",
    currentCompanyId: null,
  },
  reducers: {
    switchCompanyId: (state, action) => {
      state.currentCompanyId = action.payload
    },

  },
})

// Action creators are generated for each case reducer function
export const { switchCompanyId } = coreSlice.actions

export default coreSlice.reducer