import React from "react";
import { Dialog } from "primereact/dialog";

const ConfirmDialog = ({ visible, onHide, message, onConfirm }) => {
    return (
        <Dialog
            header="Confirm Dialog"
            visible={visible}
            onHide={onHide}
            modal
        >
            <strong>{message}</strong>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: 10 }}>
                <button
                    onClick={onHide}
                    style={{
                        border: '1px solid #ccc',
                        backgroundColor: '#fff',
                        color: '#555',
                        padding: '8px 16px',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    No
                </button>
                <button
                    onClick={onConfirm}
                    style={{
                        border: '1px solid #dc3545',
                        backgroundColor: '#dc3545',
                        color: '#fff',
                        padding: '8px 16px',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Yes
                </button>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog