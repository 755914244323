import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { Button, InputText } from 'primereact';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { addContract } from '../../../../utils/urls';
import { getUnitsForFacility, getLevies } from '../../../../utils/urls';

function AddContract() {
    const navigate = useNavigate();

    // State variables
    const [contractName, setContractName] = useState('');
    const [contractStart, setContractStart] = useState('');
    const [contractEnd, setContractEnd] = useState('');

    const [units, setUnits] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [levies, setLevies] = useState([]);
    const [levy, setLevy] = useState('');


    const fetchUnits = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${facilityId}`, 'GET', {});
            if (response.success) {
                const occupied_units = response.data.filter((x) => {
                    return x.homeOwnerId !== undefined || x.tenantId !== undefined
                })

                setUnits(occupied_units);
            } else {
                throw new Error('Error fetching units')
            }
        } catch (err) {
            console.error('Error fetching units:', err);
        }
    };

    const fetchLevies = async () => {
        try {
          const facilityId = await getItem('selectedFacilityId');
          const response = await makeRequest2(getLevies + '/' + facilityId, 'GET', {});
    
          if (response.success) {
            setLevies(response.data);
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
    };
    
    const handleContractSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId'); // Ensure this is awaited

            const contractData = {
                contractName,
                levy,
                selectedUnits,
                contractStart,
                contractEnd,
            };


            if (!contractData.contractName) throw new Error('Contract name is required.');
            if (!contractData.levy) throw new Error('Levy is required.');
            if (!contractData.selectedUnits) throw new Error('A unit is required.');
            if (!contractData.contractStart) throw new Error('Start date is required.');
            if (!contractData.contractEnd) throw new Error('End date is required.');


            const response = await makeRequest2(addContract + '/' + facilityId, 'POST', contractData);

            if (response.success) {
                toastify('Levy contract added successfully', 'success');
                setContractName('');
                setLevy('');
                setSelectedUnits([]);
                setContractStart('');
                setContractEnd('');
            }
            else {
                throw new Error(response.error);
            }
        }
        catch(err) {
            toastify(err.message, 'error')
        }
    }

       const handleClear = () => {
        setContractName('');
        setLevy('');
        setSelectedUnits([]);
        setContractStart('');
        setContractEnd('');
       };

    useEffect(() => {
        fetchUnits();
        fetchLevies();
    },[])

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/contract_management"}>Contract Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Add New Contract
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                    </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>Add New Contract</h5>
                        </div>
                        <div className='card-body'>
                            <div className='container'>
                                <div className='row'>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Contract Name<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                className="form-control"
                                                value={contractName}
                                                onChange={(e) => setContractName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control mt-2"
                                                value={levy}
                                                onChange={(e) => setLevy(e.target.value)}
                                            >
                                                 <option value="">-- Select --</option>
                                                {levies.map((levy) => (
                                                    <option key={levy._id} value={levy._id}>
                                                        {levy.levyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Units<span style={{ color: 'red' }}>*</span></label>
                                            <Autocomplete
                                                multiple // Enable multiple select
                                                options={units}
                                                getOptionLabel={(option) => option.name} // Define how each option is displayed
                                                value={selectedUnits}
                                                onChange={(event, newValue) => setSelectedUnits(newValue)} // Handle selection
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Units"
                                                        value={units}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            console.log(value)
                                                            setUnits(value)
                                                        }}
                                                        variant="outlined" />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            key={option._id}
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                } // Display selected units as chips
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Contract Start Date<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                type="date"
                                                className="form-control"
                                                value={contractStart}
                                                onChange={(e) => setContractStart(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Contract End Date<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                type="date"
                                                className="form-control"
                                                value={contractEnd}
                                                onChange={(e) => setContractEnd(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-end mt-4">
                            <Button type="button" className="btn btn-outline-secondary" onClick={handleClear}>clear</Button>
                            &nbsp;
                            <Button type="button" className="btn btn-primary" onClick={handleContractSubmit}>Submit</Button>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AddContract;
